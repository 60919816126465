<template>
  <div class="box">
    <div class="content">
      <div class="moneyItem" v-for="(item, idnex) in moneyList" :key="idnex">
        <div class="header">
          <div class="header1">
            <img
              style="width: 36px; margin-right: 10px"
              :src="item.img"
              alt=""
            />
            <span style="color: #fff">{{ item.name }}</span>
          </div>
          <div class="header2">
            <span>余额</span>
            <span style="color: #fff; margin-left: 10px">{{
              item.walletBalance
            }}</span>
          </div>
        </div>
        <div class="con">
          {{ item.con }}
        </div>
        <div class="buttons">
          <div class="button" @click="pay">
            <img
              style="width: 20px; margin-right: 6px"
              src="../myWork/images/chongzhi.png"
              alt=""
            />
            <span>充值</span>
          </div>
          <div class="button" @click="transference">
            <img
              style="width: 20px; margin-right: 6px"
              src="../myWork/images/zhuanZhang.png"
              alt=""
            />
            <span>转账</span>
          </div>
          <div class="button">
            <img
              style="width: 16px; margin-right: 6px"
              src="../myWork/images/zhangdan.png"
              alt=""
            />
            <span>账单</span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="充值ETH" :visible.sync="dialog1" width="700px">
      <div class="contentImg">
        <img
          style="height: 160px; width: 160px"
          src="@/assets/images/payETH.png"
        />
        <div class="contentRight">
          <div class="hui">主网：</div>
          <div class="hei" style="margin-bottom: 30px">Ethereum(ERC20)</div>
          <div class="hui">钱包地址：</div>
          <div class="hei">0x8e06b884026dccfd7d8bd1240a3821914d5bbca1</div>
          <img class="imgButton" src="@/assets/images/copy.png" @click="copy" />
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          style="color: #000; font-weight: 600; width: 80%; border-radius: 18px"
          @click="dialog1 = false"
          ><span
            >请务必仔细核实转账网络以及接收地址，避免造成不必要的虚拟财产损失</span
          >
        </el-button>
      </div>
    </el-dialog>
    <el-dialog title="转账ETH" :visible.sync="dialog2" width="600px">
      <el-form ref="form" :model="form" label-width="80px" size="mini">
        <el-form-item label="接收方：">
          <el-select
            v-model="form.region"
            style="width: 110px; margin-right: 10px; color: #16191e"
          >
            <el-option label="钱包地址" value="shanghai"></el-option>
            <el-option label="平台账号" value="beijing"></el-option>
          </el-select>

          <el-autocomplete
            style="width: 300px"
            class="inline-input"
            v-model="form.name"
            :fetch-suggestions="querySearch"
            placeholder="请输入接收方地址"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="数额：">
          <el-input-number
            v-model="form.num"
            style="width: 420px"
            controls-position="right"
            @change="handleChange"
            placeholder="请输入转出数额"
          ></el-input-number>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          style="color: #000; font-weight: 600"
          @click="dialog2 = false"
          >提交
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { walletList, walletDetail } from "@/api/money";
import { mapState } from "vuex";
export default {
  data() {
    return {
      moneyList: [
        {
          name: "ETH",
          walletAddress:'',
          walletBalance: '0.99',
          walltId:1,
          con: "以太坊（英语：Ethereum）是一个开源的有智能合约功能的公共区块链平台，通过其专用加密货币以太币（缩写：ETH）提供去中心化的虚拟机（Ethereum Virtual Machine）来处理点对点合约。截至2018年6月，以太币是市值第二高的加密货币，以太坊亦被称为“第二代的区块链平台”，仅次于比特币。",
          img: require("@/assets/images/ethBig.png"),
        },
        {
          name: "DAIC",
          walletBalance: '329',
          walltId:2,
          con: "几人作共创积分（缩写：DAIC），是几人作平台在以太坊链上发布的中心化代币，其核心作用在于支持平台AI共创社区的生态治理，例如：用户创作及作品发布激励、兑换增值服务、运营决策投票、标记用户权益等",
          img: require("@/components/Header/images/btc.png"),
        },
      ],
      dialog1: false, // 充值弹框
      dialog2: false, // 转账弹框
      restaurants: [],
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
    };
  },
  mounted() {
    this.getList();
    this.restaurants = this.loadAll();
  },
  methods: {
    getList() {
      walletList({ accountId: this.accountId }).then(({ code,msg, data }) => {
        if (code == 1000) {
          data.forEach(i => {
            if(i.walletType==3){
              this.moneyList[0].walletAddress = i.walletAddress
              this.moneyList[0].walletBalance = i.walletBalance
              this.moneyList[0].walltId = i.walltId
            }else if(i.walletType==1){
              this.moneyList[1].walletAddress = i.walletAddress
              this.moneyList[1].walletBalance = i.walletBalance
              this.moneyList[1].walltId = i.walltId
            }
            
          });
         
        }
      });
    },
    goToIncome(obj, type) {
      this.$store.commit("SETNFT", obj);
      this.$router.push({
        path: "/income",
        query: { type },
      });
    },
    gotoAI(obj, type) {
      this.$store.commit("SETNFT", obj);
      this.$router.push({
        path: "/AICreate",
        query: { type },
      });
    },
    noImg(list) {
      let obj = list.find((i) => i.hasMainImage);
      return obj.urlPath;
    },
    pay() {
      this.dialog1 = true;
    },
    transference() {
      this.dialog2 = true;
    },
    copy() {
      this.$copyText("0x8e06b884026dccfd7d8bd1240a3821914d5bbca1").then(
        () => {
          this.$message.success("已成功复制到粘贴板");
        },
        () => {
          this.$message("复制失败，请手动复制！");
        }
      );
    },
    onSubmit() {
      console.log("submit!");
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    loadAll() {
      return [
        { value: "三全鲜食（北新泾店）", address: "长宁区新渔路144号" },
        {
          value: "Hot honey 首尔炸鸡（仙霞路）",
          address: "上海市长宁区淞虹路661号",
        },
        {
          value: "新旺角茶餐厅",
          address: "上海市普陀区真北路988号创邑金沙谷6号楼113",
        },
        { value: "泷千家(天山西路店)", address: "天山西路438号" },
        {
          value: "胖仙女纸杯蛋糕（上海凌空店）",
          address: "上海市长宁区金钟路968号1幢18号楼一层商铺18-101",
        },
        { value: "贡茶", address: "上海市长宁区金钟路633号" },
        {
          value: "豪大大香鸡排超级奶爸",
          address: "上海市嘉定区曹安公路曹安路1685号",
        },
        {
          value: "茶芝兰（奶茶，手抓饼）",
          address: "上海市普陀区同普路1435号",
        },
        { value: "十二泷町", address: "上海市北翟路1444弄81号B幢-107" },
        { value: "星移浓缩咖啡", address: "上海市嘉定区新郁路817号" },
        { value: "阿姨奶茶/豪大大", address: "嘉定区曹安路1611号" },
        { value: "新麦甜四季甜品炸鸡", address: "嘉定区曹安公路2383弄55号" },
        {
          value: "Monica摩托主题咖啡店",
          address: "嘉定区江桥镇曹安公路2409号1F，2383弄62号1F",
        },
        {
          value: "浮生若茶（凌空soho店）",
          address: "上海长宁区金钟路968号9号楼地下一层",
        },
        { value: "NONO JUICE  鲜榨果汁", address: "上海市长宁区天山西路119号" },
        { value: "CoCo都可(北新泾店）", address: "上海市长宁区仙霞西路" },
        {
          value: "快乐柠檬（神州智慧店）",
          address: "上海市长宁区天山西路567号1层R117号店铺",
        },
        {
          value: "Merci Paul cafe",
          address: "上海市普陀区光复西路丹巴路28弄6号楼819",
        },
        {
          value: "猫山王（西郊百联店）",
          address: "上海市长宁区仙霞西路88号第一层G05-F01-1-306",
        },
        { value: "枪会山", address: "上海市普陀区棕榈路" },
        { value: "纵食", address: "元丰天山花园(东门) 双流路267号" },
        { value: "钱记", address: "上海市长宁区天山西路" },
        { value: "壹杯加", address: "上海市长宁区通协路" },
        {
          value: "唦哇嘀咖",
          address: "上海市长宁区新泾镇金钟路999号2幢（B幢）第01层第1-02A单元",
        },
        { value: "爱茜茜里(西郊百联)", address: "长宁区仙霞西路88号1305室" },
        {
          value: "爱茜茜里(近铁广场)",
          address:
            "上海市普陀区真北路818号近铁城市广场北区地下二楼N-B2-O2-C商铺",
        },
        {
          value: "鲜果榨汁（金沙江路和美广店）",
          address: "普陀区金沙江路2239号金沙和美广场B1-10-6",
        },
        {
          value: "开心丽果（缤谷店）",
          address: "上海市长宁区威宁路天山路341号",
        },
        { value: "超级鸡车（丰庄路店）", address: "上海市嘉定区丰庄路240号" },
        { value: "妙生活果园（北新泾店）", address: "长宁区新渔路144号" },
        { value: "香宜度麻辣香锅", address: "长宁区淞虹路148号" },
        {
          value: "凡仔汉堡（老真北路店）",
          address: "上海市普陀区老真北路160号",
        },
        { value: "港式小铺", address: "上海市长宁区金钟路968号15楼15-105室" },
        { value: "蜀香源麻辣香锅（剑河路店）", address: "剑河路443-1" },
        { value: "北京饺子馆", address: "长宁区北新泾街道天山西路490-1号" },
        {
          value: "饭典*新简餐（凌空SOHO店）",
          address: "上海市长宁区金钟路968号9号楼地下一层9-83室",
        },
        {
          value: "焦耳·川式快餐（金钟路店）",
          address: "上海市金钟路633号地下一层甲部",
        },
        { value: "动力鸡车", address: "长宁区仙霞西路299弄3号101B" },
        { value: "浏阳蒸菜", address: "天山西路430号" },
        { value: "四海游龙（天山西路店）", address: "上海市长宁区天山西路" },
        {
          value: "樱花食堂（凌空店）",
          address: "上海市长宁区金钟路968号15楼15-105室",
        },
        { value: "壹分米客家传统调制米粉(天山店)", address: "天山西路428号" },
        {
          value: "福荣祥烧腊（平溪路店）",
          address: "上海市长宁区协和路福泉路255弄57-73号",
        },
        {
          value: "速记黄焖鸡米饭",
          address: "上海市长宁区北新泾街道金钟路180号1层01号摊位",
        },
        { value: "红辣椒麻辣烫", address: "上海市长宁区天山西路492号" },
        {
          value: "(小杨生煎)西郊百联餐厅",
          address: "长宁区仙霞西路88号百联2楼",
        },
        { value: "阳阳麻辣烫", address: "天山西路389号" },
        {
          value: "南拳妈妈龙虾盖浇饭",
          address: "普陀区金沙江路1699号鑫乐惠美食广场A13",
        },
      ];
    },
    handleChange() {},
  },
  computed: {
    ...mapState({
      accountId: (state) => state.user.accountId,
    }),
  },
};
</script>

<style scoped lang="less">
.box {
  width: 1200px;
  margin: 20px auto;
  min-height: 80vh;
  .content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .buttons {
      margin-top: 14px;
      display: flex;
      .button {
        display: flex;
        align-items: center;
        color: #01e39b;
        font-size: 14px;
        cursor: pointer;
        margin-left: 20px;
      }
    }
    .moneyItem {
      width: 310px;
      height: 240px;
      box-sizing: border-box;
      padding: 20px;
      margin-right: 30px;
      border-radius: 12px;
      background: #2d3034;
      color: #828282;
      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 18px;
        margin-bottom: 20px;
        .header1 {
          display: flex;
          align-items: center;
        }
      }
      .con {
        height: 110px;
        font-size: 12px;
      }
    }

    .listbox {
      width: 100%;
      position: relative;
      .datasButton {
        color: #fff;
        font-size: 50px;
      }
      .nodatasButton {
        color: #828282;
        font-size: 50px;
      }
      .butLeft {
        position: absolute;
        top: 100px;
        z-index: 999;
        left: -40px;
      }
      .butRight {
        position: absolute;
        top: 100px;
        z-index: 999;
        right: -40px;
      }
    }
    .listTit {
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #333;
      margin-bottom: 20px;
      margin-top: 30px;
      color: #ccc;
    }

    .listCon {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .list {
        width: 30%;
        display: flex;
        background: #2d3034;
        border-radius: 12px;
        padding: 14px;
        .imgs {
          width: 160px;
          height: 250px;
          border-radius: 8px;
          margin-right: 14px;
          border: 4px solid #999;
          background: url(../../assets/images/creatImg.png) no-repeat;
          background-size: 100% 100%; /* 背景图充满元素 */
          .dataImg {
            margin: 160px auto;
            height: 80px;
            width: 140px;
            border: solid 2px #ccc;
            border-radius: 8px;
          }
        }
        .noImgs {
          height: 80px;
          width: 140px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-flow: column;
          padding: 10px;
          margin: 160px auto;
          border: solid 2px #ccc;
          border-radius: 8px;
          background-color: #16191e;
          .top {
            display: flex;
            align-items: center;
            color: #828282;
            font-size: 14px;
          }
          .noImgsButton {
            display: flex;
            align-items: center;
            color: #01e39b;
            cursor: pointer;
          }
        }
        .imgs1 {
          width: 160px;
          height: 250px;
          margin-right: 14px;
          background: url(../../assets/images/Continuation.png) no-repeat;
          background-size: 100% 100%; /* 背景图充满元素 */
          .dataImg {
            margin: 160px auto;
            height: 80px;
            width: 140px;
            border: solid 2px #ccc;
            border-radius: 8px;
          }
        }
        .conte {
          .title {
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 16px;
          }
          .item {
            width: 100%;
            line-height: 24px;
            font-size: 12px;
            .label {
              display: inline-block;
              width: 70px;
            }
            .value {
              color: #828282;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .contentImg {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .contentRight {
      position: relative;
      flex: 1;
      margin-left: 20px;
      .hui {
        color: #828282;
        height: 30px;
      }
      .hei {
        color: #000;
        height: 30px;
      }
      .imgButton {
        position: absolute;
        right: 30px;
        bottom: 18px;
        cursor: pointer;
      }
    }
  }
  .dialog-footer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
