import request from './request';

//钱包列表
export const walletList = (data) => request({
    url: '/wallet/list',
    method: 'post',
    data
});

//钱包详情
export const walletDetail = (data) => request({
    url: '/wallet/detail',
    method: 'post',
    data
});